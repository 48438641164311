import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import * as yup from 'yup'

import {
  ChannelCreate,
  ChannelStatus,
  ChannelType,
  CreateChannelDocument,
  GetChannelByIdDocument,
  NotificationSettingScope,
  PaymentOptions,
  UpdateChannelDocument,
  UploadImageDocument,
  ChannelSettingsScope,
  ChannelSettingsName,
  SettingsUpdate,
} from '@app/graphql'
import { FormElementCreation, PageLayout } from '@app/components'
import { CreateChannelValues, FormFieldDataType, FormFieldType } from '@app/types'
import { DialogResult } from '@app/ui'
import NotificationTab from '@app/components/Notification/NotificationsTab'
import { useCallbackPrompt } from '@app/hooks'
import { FormHelper } from '@app/helpers'
import styled from 'styled-components'

enum TabOptions {
  data = 'DATA',
  notifications = 'NOTIFICATIONS',
  settings = 'SETTINGS'
}

const titleField = 'title'
const logoField = 'logoFile'
const backgroundField = 'backgroundFile'
const countryField = 'country'
const typeField = 'type'
const anonymityField = 'anonymity'
const shareField = 'share'
const dealField = 'deal'
const feeField = 'fee'
const trialField = 'trial'
const usersLimitField = 'usersLimit'
const valueField = 'settingValue'
const scopeField = 'settingScope'

interface ChannelCreatePageProps {
  editMode?: boolean
}

const ChannelCreatePage: FC<ChannelCreatePageProps> = ({ editMode }) => {
  const [ blockLeaving, setBlockLeaving ] = useState(true)
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ loading, setLoading ] = useState(true)
  const [ currentTab, setCurrentTab ] = useState(TabOptions.data)
  const { channelId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const { dialog } = useCallbackPrompt(blockLeaving)
  const [
    getChannel,
    { data: channelData, loading: channelLoading, error: channelError }
  ] = useLazyQuery(GetChannelByIdDocument, { fetchPolicy: 'network-only' })
  const [ createChannel ] = useMutation(CreateChannelDocument)
  const [ updateChannel ] = useMutation(UpdateChannelDocument)
  const [ uploadImage ] = useMutation(UploadImageDocument, { context: { operation: 'imageUpload' } })

  useEffect(() => {
    if (editMode) getChannel({ variables: { channelId } })
    setLoading(false)
  }, [])

  const onSubmit = async (values: CreateChannelValues) => {
    const upload = async (file: File) => {
      if (file) {
        const result = await uploadImage({ variables: { image: file } })
        return result?.data?.imageUpload
      }
      return Promise.resolve(null)
    }
    try {
      setLoading(true)
      setBlockLeaving(false)

      const [ logoPath, backgroundPath ] = await Promise.all([
        upload(values.logoFile),
        upload(values.backgroundFile),
      ])

      const input: ChannelCreate = {
        anonymized: values.anonymity,
        channelDeal: values.deal,
        channelName: values.title,
        channelType: values.type,
        countryId: values.country,
        feeForPayment: values.fee,
        paymentOptions: PaymentOptions.Stripe,
        trialSubscription: values.trial,
        usersLimit: values.usersLimit,
      }
      if (logoPath) input.logo = logoPath
      if (backgroundPath) input.background = backgroundPath

      let updated: boolean
      if (editMode) {
        if(values.settings) {
          Object.assign(input, {settings: values.settings})
        }
        const result = await updateChannel({ variables: { channelId, input } })
        updated = result.data.channelUpdate.updated
      } else {
        const result = await createChannel({ variables: { input } })
        updated = Boolean(result.data.channelCreate.id)
      }

      if (updated) {
        setSuccessMessage(editMode ? t('updatingSuccess') : t('creationSuccess'))
      } else {
        throw new Error()
      }
    } catch (e) {
      setBlockLeaving(true)
      setErrorMessage(editMode ? t('updatingChannelFailure') : t('creationChannelFailure'))
    } finally {
      setLoading(false)
    }
  }
  const onCancel = () => navigate(-1)
  const onChangeTab = (e: SyntheticEvent, newValue: TabOptions) => setCurrentTab(newValue)
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      navigate(-1)
    }
  }
  const onChangeStatus = async (isActive: boolean) => {
    if (!editMode) return

    try {
      setLoading(true)
      const channelStatus = isActive ? ChannelStatus.Inactive : ChannelStatus.Active
      const {
        anonymized,
        channelDeal,
        channelName,
        channelType,
        feeForPayment,
        paymentOptions,
        trialSubscription,
        countryId,
      } = channelData.channel
      const result = await updateChannel({
        variables: {
          channelId: channelData.channel.id,
          input: {
            channelStatus,
            anonymized,
            channelDeal,
            channelName,
            channelType,
            feeForPayment,
            paymentOptions,
            trialSubscription,
            countryId,
          },
        },
      })
      if (result.data.channelUpdate.updated) await getChannel({
        variables: {
          channelId: channelData.channel.id
        }
      })
    } catch (e) {
      setErrorMessage(t('updatingChannelFailure'))
    } finally {
      setLoading(false)
    }
  }

  const pageTitle = editMode ? t('editChannel', { ns: 'pageTitles' }) :
    t('createChannel', { ns: 'pageTitles' })
  const title = editMode ? t('editChannel') : t('createNewChannel')

  const fields: FormFieldDataType[] = [
    {
      name: titleField,
      label: t('title'),
      type: FormFieldType.text,
      initValue: channelData?.channel?.channelName || '',
      validation: FormHelper.getRequiredStringSchema()
        .min(3, t('min3LengthError', { ns: 'forms' }))
        .max(40, t('max40LengthError', { ns: 'forms' })),
      required: true,
    },
    {
      name: logoField,
      label: t('uploadLogo'),
      type: FormFieldType.image,
      initValue: null,
      validation: yup.mixed(),
      required: true,
      image: {
        link: channelData?.channel?.logo,
        alt: 'Channel Logo',
        width: 80,
        height: 80,
        circle: true,
      },
    },
    {
      name: backgroundField,
      label: t('background'),
      type: FormFieldType.image,
      initValue: null,
      validation: yup.mixed(),
      image: {
        link: channelData?.channel?.background,
        alt: 'Channel Background',
        width: 360,
        height: 120,
      },
    },
    {
      name: countryField,
      label: t('country'),
      type: FormFieldType.country,
      initValue: channelData?.channel?.countryId || '',
      validation: FormHelper.getRequiredStringSchema(),
      required: true,
    },
    {
      name: typeField,
      label: t('type'),
      type: FormFieldType.select,
      elements: [
        { value: ChannelType.Private },
        { value: ChannelType.Public },
      ],
      initValue: channelData?.channel?.channelType || ChannelType.Private,
      validation: FormHelper.getRequiredStringSchema(),
      required: true,
    },
    {
      name: anonymityField,
      label: t('anonymity'),
      type: FormFieldType.checkbox,
      initValue: channelData?.channel?.anonymized || false,
      validation: yup.boolean(),
    },
    {
      name: shareField,
      label: t('share'),
      type: FormFieldType.checkbox,
      initValue: false,
      validation: yup.boolean(),
    },
    {
      name: dealField,
      label: t('deal'),
      type: FormFieldType.number,
      initValue: channelData?.channel?.channelDeal || 0,
      validation: yup.number()
        .min(0, t('minNumberZeroError', { ns: 'forms' }))
        .max(100, t('maxNumber100Error', { ns: 'forms' })),
    },
    {
      name: feeField,
      label: t('fee'),
      type: FormFieldType.number,
      initValue: channelData?.channel?.feeForPayment || 0,
      validation: yup.number()
        .min(0, t('minNumberZeroError', { ns: 'forms' }))
        .max(100, t('maxNumber100Error', { ns: 'forms' })),
    },
    {
      name: trialField,
      label: t('trialPeriod'),
      type: FormFieldType.number,
      initValue: channelData?.channel?.trialSubscription || 0,
      validation: yup.number()
        .min(0, t('minNumberZeroError', { ns: 'forms' })),
    },
    {
      name: usersLimitField,
      label: t('usersLimit'),
      type: FormFieldType.number,
      initValue: channelData?.channel?.usersLimit || 0,
      validation: yup.number()
        .min(0, t('minNumberZeroError', { ns: 'forms' })),
    },
  ]

  const BuildsettingFields = (setting: any): FormFieldDataType[] => {
    let settingFormConfig: FormFieldDataType = {
      name: `${valueField}-${setting.id}`,
      label: t('Value'),
      type: FormFieldType.text,
      initValue: `${setting.value}`,
      validation: (
        FormHelper.getRequiredStringSchema()
          .min(0, t('min3LengthError', { ns: 'forms' }))
          .max(40, t('max40LengthError', { ns: 'forms' }))
      ),
      required: false,
      multiple: false,
    }

    switch (setting.name) {
      case ChannelSettingsName.VoteBotLimit: {
        const [publicMaxBotVote, privateBotVote, maxBotDownVote, delayAction, updateInterval] = setting.value.split(',')
        return [
          {
            name: `${valueField}-public-${setting.id}`,
            label: t('Public Max'),
            type: FormFieldType.number,
            initValue: `${publicMaxBotVote}`,
            validation: (
              FormHelper.getRequiredStringSchema()
                .min(0, t('min3LengthError', { ns: 'forms' }))
                .max(40, t('max40LengthError', { ns: 'forms' }))
            ),
            required: false,
            multiple: false,
          },
          {
            name: `${valueField}-private-${setting.id}`,
            label: t('Private Max'),
            type: FormFieldType.number,
            initValue: `${privateBotVote}`,
            validation: (
              FormHelper.getRequiredStringSchema()
                .min(0, t('min3LengthError', { ns: 'forms' }))
                .max(40, t('max40LengthError', { ns: 'forms' }))
            ),
            required: false,
            multiple: false,
          },
          {
            name: `${valueField}-down-${setting.id}`,
            label: t('Max Down'),
            type: FormFieldType.number,
            initValue: `${maxBotDownVote}`,
            validation: (
              FormHelper.getRequiredStringSchema()
                .min(0, t('min3LengthError', { ns: 'forms' }))
                .max(40, t('max40LengthError', { ns: 'forms' }))
            ),
            required: false,
            multiple: false,
          },
          {
            name: `${valueField}-delayAction-${setting.id}`,
            label: t('Delay Action (Minutes)'),
            type: FormFieldType.number,
            initValue: `${delayAction}`,
            validation: (
              FormHelper.getRequiredStringSchema()
                .min(0, t('min3LengthError', { ns: 'forms' }))
                .max(40, t('max40LengthError', { ns: 'forms' }))
            ),
            required: false,
            multiple: false,
          },
          {
            name: `${valueField}-updateInterval-${setting.id}`,
            label: t('Update Interval  (Minutes)'),
            type: FormFieldType.number,
            initValue: `${updateInterval}`,
            validation: (
              FormHelper.getRequiredStringSchema()
                .min(0, t('min3LengthError', { ns: 'forms' }))
                .max(40, t('max40LengthError', { ns: 'forms' }))
            ),
            required: false,
            multiple: false,
          },
          {
            name: scopeField,
            label: t('Scope'),
            type: FormFieldType.select,
            elements: Object.values(ChannelSettingsScope).map(scope => ({value: scope})),
            initValue: setting.scope,
            validation: FormHelper.getRequiredStringSchema(),
            disabled: true,
          }
        ]
      }
      case ChannelSettingsName.AllowedPaymentType:
        settingFormConfig = {
          name: `${valueField}-${setting.id}`,
          label: t('Value'),
          type: FormFieldType.select,
          elements: [
            { value: 'manual' },
            { value: 'auto' },
          ],
          initValue: setting.value.split(','),
          validation: yup.array().of(yup.string()).min(1, t('requiredError', { ns: 'forms' })),
          required: true,
          multiple: true,
        }
        break

      case ChannelSettingsName.VoteMultiplierBotTrigger:
        settingFormConfig = {
          ...settingFormConfig,
          type: FormFieldType.user,
          required: false,
        }
        break
    }

    return [
      settingFormConfig,
      {
        name: scopeField,
        label: t('Scope'),
        type: FormFieldType.select,
        elements: Object.values(ChannelSettingsScope).map(scope => ({value: scope})),
        initValue: setting.scope,
        validation: FormHelper.getRequiredStringSchema(),
        required: true,
      },
    ]
  }

  if (editMode && (!channelId || channelError)) navigate(-1)
  // todo: check is admin or tipster of the channel
  const onSubmitSettings = async (setting, data) => {
    let settings: SettingsUpdate
    switch (setting.name) {
      case ChannelSettingsName.VoteBotLimit:{
        const publicVote = `${valueField}-public-${setting.id}`
        const privateVote = `${valueField}-private-${setting.id}`
        const delayAction = `${valueField}-delayAction-${setting.id}`
        const updateInterval = `${valueField}-updateInterval-${setting.id}`
        const downVote = `${valueField}-down-${setting.id}`
        settings = {
          id: setting.id,
          scope: data.settingScope,
          value: `${data[publicVote]},${data[privateVote]},${data[downVote]},${data[delayAction]},${data[updateInterval]}`
        }
        break
      }
      default:
        settings = {
          id: setting.id,
          scope: data.settingScope,
          value: data[`${valueField}-${setting.id}`]
        }
        break
    }
    await onSubmit({...data, settings})
  }

  return (
    <PageLayout title={pageTitle} loading={(editMode && channelLoading) || loading}>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h4" gutterBottom>
          {title}
        </Typography>
        {
          editMode ?
            <Tabs
              value={currentTab}
              onChange={onChangeTab}
            >
              <Tab
                label={t('data')}
                value={TabOptions.data}
              />
              <Tab
                label={t('notifications')}
                value={TabOptions.notifications}
              />
              <Tab
                label={t('settings')}
                value={TabOptions.settings}
              />
            </Tabs> :
            null
        }
        {
          currentTab === TabOptions.data ?
            <Box sx={{ width: '700px' }}>
              <FormElementCreation
                fields={fields}
                state={{
                  active: channelData?.channel?.channelStatus === ChannelStatus.Active,
                }}
                onSubmit={onSubmit}
                onCancel={onCancel}
                onActivate={editMode ? onChangeStatus : null}
              />
            </Box> :
            null
        }
        {
          currentTab === TabOptions.notifications ?
            <NotificationTab
              scope={NotificationSettingScope.Channel}
              channelId={channelId}
            /> :
            null
        }
        {
          currentTab === TabOptions.settings ?
            <>
              {channelData?.channel.settings.map(setting => (
                <SettingBox sx={{ width: '700px' }} key={setting.id}>
                  <p className="title">{setting.name.replaceAll('_', ' ')}</p>
                  <FormElementCreation
                    fields={BuildsettingFields(setting)}
                    onSubmit={(data) => onSubmitSettings(setting, data)}
                  />
                </SettingBox>
              ))}
            </>:
            null
        }
      </Box>

      {/* DIALOG */}
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
      { dialog }
    </PageLayout>
  )
}

const SettingBox = styled(Box)`
  background-color: var(--color-gray-light);
  .title {
    padding 10px;
  }
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
`

export default ChannelCreatePage
